<template>
  <div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="reload"
    >
      <template #table>
        <b-table
          ref="refUsersTable"
          class="position-relative"
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          no-provider-filtering
          sticky-header="65vh"
          :busy.sync="isBusy"
          :sort-direction="order"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(users)="data">
            <div :class="[textLink]">
              {{ data.item.users }}
            </div>
          </template>

          <template #cell(status_session)="data">
            <span
              class="badget-wo-color"
              style="text-transform: uppercase"
              :style="
                data.item.status_session == 1
                  ? 'background-color: rgba(0, 204, 0, 0.2); color: rgba(0, 204, 0, 1);'
                  : 'background-color: rgba(151, 151, 151, 0.2); color: rgba(99, 99, 99, 1);'
              "
            >
              {{ data.item.status_session == 1 ? "Online" : "Offline" }}
            </span>
          </template>

          <template #cell(created_by)="data">
            {{ data.item.created_by }}
            <br />
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </template>

          <!-- ANALYST ASSIGNMENTS -->
          <template #cell(actions)="data">
            <b-form-group>
              <div
                v-for="assignment in assignments"
                :key="assignment.id"
                class="d-flex justify-content-between align-items-center mt-1"
              >
                <b-form-checkbox
                  v-model="data.item['a_' + assignment.toLowerCase()]"
                  value="1"
                  unchecked-value="0"
                  @change="
                    changeAssignment({
                      analyst_id: data.item.id,
                      a_update: parseInt(data.item.a_update),
                      a_workplan: parseInt(data.item.a_workplan),
                    })
                  "
                >
                  <span>{{ assignment }}</span>
                </b-form-checkbox>
              </div>
            </b-form-group>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataFields from "@/views/specialists/sub-modules/analyst-department/views/settings/data/TabActiveFields";
import SettingsService from "../../service/settings.service";

export default {
  data() {
    return {
      assignments: ["Workplan", "Update"],
      fields: dataFields,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      items: [],
      order: "desc",
      sortBy: "created_at",

      dataItem: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          perPage: ctx.perPage,
          page: ctx.currentPage,
          search: this.filterPrincipal.model,
        };
        const data = await SettingsService.searchUsers(params);

        this.items = data.data;

        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        return this.items || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async changeAssignment(params) {
      try {
        await SettingsService.assignment(params);
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
        console.log(e);
      }
    },
    async reload() {
      this.$refs["refUsersTable"].refresh();
    },
  },
};
</script>

<style>
.badget-wo-color {
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
