import { amgApi } from "@/service/axios";

class SettingsServiceSp {
  async searchUsers(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-credit-analyst-actives", params)

      return data

    } catch (error) {
      console.log("Something went wrong on searchUsers ", error);
      throw error
    }
  }

  async assignment(params) {
    try {
      const data = await amgApi.post("/specialists/settings/assignment-modification", params)

      return data

    } catch (error) {
      console.log("Something went wrong on assignment ", error);
      throw error
    }
  }

  async getRules(params){
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/get",params)
      return data
    } catch (error) {
      throw error
    }
  }

  async updateRule(params){
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/update", params)
      return data
    } catch (error) {
      throw error
    }
  }

  async deleteWord(params){
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/update", params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getRuleTracking(params){
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rule-tracking/get", params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getRuleResultTracking(params){
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rule-tracking/get-result", params)
      return data
    } catch (error) {
      throw error
    }
  }
  
  
  async getRulesForRemoval(params) {
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/get-remove", params)

      return data

    } catch (error) {
      console.log("Something went wrong on assignment ", error);
      throw error
    }
  }

  async updateRulesForRemoval(params) {
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/update-remove", params)

      return data

    } catch (error) {
      console.log("Something went wrong on assignment ", error);
      throw error
    }
  }

  async getRulesForRemovalResult(params) {
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/index-result", params)

      return data

    } catch (error) {
      console.log("Something went wrong on assignment ", error);
      throw error
    }
  }

  async updateResult(params) {
    try {
      const data = await amgApi.post("/specialists/analysis-department/settings/rules/update-result", params)

      return data

    } catch (error) {
      console.log("Something went wrong on assignment ", error);
      throw error
    }
  }
}

export default new SettingsServiceSp();
