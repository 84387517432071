import { render, staticRenderFns } from "./AssignmentModification.vue?vue&type=template&id=2c5a74d0"
import script from "./AssignmentModification.vue?vue&type=script&lang=js"
export * from "./AssignmentModification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports