export default [
  {
    key: "users",
    label: "Users",
    visible: true,
  },

  {
    key: "email",
    label: "Email",
    visible: true,
  },
  {
    key: "status_session",
    label: "Status",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "role",
    label: "Role",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_by",
    label: "Created By",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    label: "Actions",
    visible: true,
  },
];
