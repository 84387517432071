<template>
  <div>
    <TabActive />
  </div>
</template>
<script>

import TabActive from "@/views/specialists/sub-modules/analyst-department/views/settings/views/components/TabActive.vue";
export default {
  name: "AssignmentModification",
  components: {
    TabActive,
  },
};
</script>
